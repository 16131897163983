
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import depositBanner from '~/mixins/common/deposit-banner'
export default {
    name: 'DepositBanner',
    mixins: [cms, depositBanner],
    props: {
        maxWidth: {
            type: [Number, String],
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            bannerDeposit: 'cms/bannerDeposit',
        }),
        displayBannerDeposit() {
            if (this.bannerDeposit._id) {
                return this.bannerDeposit.is_visible
            }
            return false
        },
        currentImagePath() {
            return this.getLocaleLang(
                this.isMobileOnly()
                    ? this.bannerDeposit.thumbnail_path
                    : this.bannerDeposit.image_path
            )
        },
    },
}
