
import fingerprint from '~/mixins/fingerprint'
import interRegister from '~/mixins/pages/register/interRegister'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme2Register',
    mixins: [fingerprint, interRegister, cms],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        // to check if this component is called by Navbar or Register Page
        registerPage: {
            type: Boolean,
            default: false,
        },
    },
    async mounted() {
        const countryCode = this.$cookie?.get('country_code')
        await this.countryChange(countryCode)
        this.form.reg_fingerprint = await this.visitorId()
        this.form.country_code = countryCode
        const globalReg = this.$cookie?.get('global_reg')
        this.globalReg = globalReg
    },
    methods: {
        onLoginNow() {
            if (this.registerPage) {
                this.$router.push('/login')
            } else {
                this.toggle()
            }
        },
        onClose() {
            if (this.registerPage) {
                this.$router.push('/')
            } else {
                this.$emit('input', false)
            }
        },
        register() {
            this.$refs?.refRegister?.validate()
            if (this.formValid && this.acceptCondition) {
                this.onRegister()
            }
        },
        onChangeBank(bankId) {
            const getSelectedBank = this.banks.find((el) => el.id === bankId)
            if (getSelectedBank?.supported_currency) {
                this.currencies = [...getSelectedBank.supported_currency]
            }
        },
    },
}
