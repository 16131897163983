import { render, staticRenderFns } from "./InterRegisterForm.vue?vue&type=template&id=1636feed&scoped=true"
import script from "./InterRegisterForm.vue?vue&type=script&lang=js"
export * from "./InterRegisterForm.vue?vue&type=script&lang=js"
import style0 from "./InterRegisterForm.vue?vue&type=style&index=0&id=1636feed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1636feed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default,CommonsChipCurrency: require('/app/components/Commons/ChipCurrency.vue').default,CommonsPhoneInput: require('/app/components/Commons/PhoneInput/index.vue').default})
